.background-section {
  --tw-bg-opacity: 1;
  background-color: rgb(229 231 235 / var(--tw-bg-opacity));
  }

  .hero {
    min-height: 100vh;
}

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid #3498db;
    width: 60px;
    height: 60px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
  }

  .bg-silver-200 {
    background: linear-gradient(135deg, #E3E3E3 0%, #F7F7F7 100%);
  }
  
  /* Animación del spinner */
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  @media (max-width: 600px) {
    .hero {
      width: 100dvw;
      min-height: 0;
      background-position: top center;
    }
  }