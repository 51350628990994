  .image-wrapper {
    position: relative;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 1;
  }
  
  .overlay:hover {
    opacity: 1;
  }
  
  .project-details {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s;
    z-index: 2;
  }
  
  .overlay:hover + .project-details {
    opacity: 1;
  }
  

  @media screen and (max-width: 424px) {
    .img_title {
      width: 50%;
      font-size: 18px;
      text-align: center;
    }
  }