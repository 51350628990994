@media (max-width: 320px) {
    .team_member {
      flex-direction: column;
      align-items: center;
      text-align: center;
      padding: 0;
      padding-bottom: 12px;
    }
  
    .team_member img {
      width: 100%;
      height: 255.69px;
      margin-bottom: 0;
      margin-right: 0;
      border-radius: 8px 8px 0 0;
    }
  
    .team_member div {
      margin-top: 1rem;
    }
  
    .team_member h3 {
      font-size: 1.5rem;
      
    }
  
    .team_member p {
      font-size: 1rem;
      

    }
  }
  