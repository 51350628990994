@keyframes fadeUp {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  
  .success-message {
    animation: fadeUp 2s ease-out;
  }